import React, { Component } from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import { getTranslations, changeToLocalLinks, createMetaDesc } from "../components/helpers/helpers";
import i18Data from '../../content/intl/labels.json';

import "../styles/page.scss"

class Page extends Component {

    componentDidMount() {
        this.windowRef = window;
        this.documentRef = document;
        this.linksUnsubs = changeToLocalLinks(this.isHtml, navigate, [ i18Data.common.url, i18Data.common.altUrl ]);
    }

    componentWillUnmount() {
        if (this.isHtml) {
            this.isHtml.removeEventListener("click", this.linksUnsubs);
        }
    }

    render() {
        const { data: { pageData }, pageContext: { lang, translateKey } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, translateKey);
        const textDesc = createMetaDesc(page.frontmatter.summary || page.excerpt);
        const i18 = i18Data[ lang ];

        return (
            <Layout mainClassName="page" data={{}} noActiveMenu={true} pageContext={this.props.pageContext} 
                translations={translations}>

                <SEO title={page.frontmatter.title} 
                    description={textDesc} lang={lang ? lang : "en" }/>
                
                <div className="page-container initial-padding">
                    <div className="page-top">
                        <ul className="breadcrumbs">
                            <li><Link to="../..">{i18.labels.teachingMaterials}</Link></li>
                            <li><Link to="..">{page.frontmatter.subject}</Link></li>
                        </ul>
                        <h1 dangerouslySetInnerHTML={{ __html: page.frontmatter.title }}></h1>
                    </div>

                    <div className="is-html with-headers max-width" 
                        ref={(t) => { this.isHtml = t }}
                        dangerouslySetInnerHTML={{ __html: page.html }}></div>                    

                    <br /><br />
                    <Link className="rounded-padded-link fontSize85" to="..">&larr; {i18.labels.back}</Link>

                </div>

            </Layout>
        )
    }
}

export default Page


export const query = graphql`
    query MatDocContentQuery($translateKey: String!) {
        pageData: allMarkdownRemark(filter: { 
            frontmatter: { template:{ eq: "matDocItem" }, 
            translateKey: { eq: $translateKey }
        }}) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                        translateKey
                        subject
                        subjectId
                        originalRef
                        type
                    }
                    excerpt
                    html
                }
            }
        }

    }
`